import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import { reduxForm, type InjectedFormProps } from "redux-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import WorkflowModal from "common/modals/workflow_modal";
import { useQuery, useMutation } from "util/graphql";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import { DeprecatedTextField } from "common/form/fields/text";
import Button from "common/core/button";
import { captureException } from "util/exception";
import { composeValidators } from "util/form";
import { validatePresence } from "validators/form";
import { encodeSearchParams } from "util/location";
import TitleOnboardingModalSteps from "assets/images/title-onboarding-modal-steps.png";
import { NONONBOARDED } from "title_portal/sign_up";

import TransactionViewTitleOnboarding, {
  type TransactionViewTitleOnboarding_viewer_user as User,
} from "./index.graphql";
import UpdateTitleAgencyMutation from "./update_title_agency_from_nononboarded_flow_mutation.graphql";

type WrapperProps = {
  organizationId: string;
};
type Props = { user: User } & WrapperProps;
type InnerProps = InjectedFormProps<FormValues, Props> & Props;
type FormValues = {
  organizationName: string | null;
  firstName: string | null;
  lastName: string | null;
};

const validate = (values: FormValues) =>
  composeValidators(
    validatePresence({ field: "organizationName", label: "Company name" }),
    validatePresence({ field: "firstName", label: "First name" }),
    validatePresence({ field: "lastName", label: "Last name" }),
  )(values);

const TransactionViewTitleOnboardingModal = ({
  organizationId,
  handleSubmit,
  user,
  initialize,
}: InnerProps) => {
  const organization = user.organization!;
  const [searchParams] = useSearchParams();
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(
    searchParams.get("flow") === NONONBOARDED && searchParams.get("needSetup"),
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    initialize({
      firstName: user.firstName,
      lastName: user.lastName,
      organizationName: organization.name,
    });
  }, []);
  const updateTitleAgencyMutateFn = useMutation(UpdateTitleAgencyMutation);

  const CX = "TitleAccountOnboardingAccountSetup";

  if (isOnboardingModalOpen) {
    return (
      <WorkflowModal large>
        <div className={CX}>
          <img
            className={`${CX}--modal-steps`}
            alt="onboarding-modal"
            src={TitleOnboardingModalSteps}
          />
          <div className={`${CX}--modal-title`}>
            <FormattedMessage
              id="b63fc8aa-2e5d-4aa1-99df-ce8695e39067"
              defaultMessage="Tell us about you"
            />
          </div>
          <div>
            <FormattedMessage
              id="48964fa9-8e6c-4482-98cd-90bf8758afbc"
              defaultMessage="This information helps us set up your notarize account."
            />
          </div>
          <div className={`${CX}--form`}>
            <FormGroup
              className={`${CX}--form-section`}
              fields={["firstName", "lastName"]}
              disableFormRowStyle
            >
              <label
                htmlFor="transactionViewOnboardingTitleFirstName"
                className={`${CX}--form-section-header`}
              >
                <FormattedMessage
                  id="b77278d9-0630-4f3d-9741-94137d4cad1d"
                  defaultMessage="What's your name?"
                />
              </label>
              <div className={`${CX}--two-part-input`}>
                <DeprecatedTextField
                  id="transactionViewOnboardingTitleFirstName"
                  name="firstName"
                  placeholder={
                    <FormattedMessage
                      id="ec74be68-b33a-4713-9594-16fafba24bed"
                      defaultMessage="First Name"
                    />
                  }
                  displayRequiredAsterisk
                  useStyledInput
                />
                <DeprecatedTextField
                  name="lastName"
                  placeholder={
                    <FormattedMessage
                      id="b41a1899-2d3c-4db3-8fae-0139eb0b6b2f"
                      defaultMessage="Last Name"
                    />
                  }
                  displayRequiredAsterisk
                  useStyledInput
                />
              </div>
              <FormGroupErrors fields={["firstName", "lastName"]} alert />
            </FormGroup>
            <FormGroup
              className={`${CX}--form-section`}
              fields={["organizationName"]}
              disableFormRowStyle
            >
              <label
                htmlFor="transactionViewOnboardingTitleOrganizationName"
                className={`${CX}--form-section-header`}
              >
                <FormattedMessage
                  id="d47cfaaa-59a6-4e39-a3cd-e32cb1339cf7"
                  defaultMessage="What's your company's name?"
                />
              </label>
              <DeprecatedTextField
                id="transactionViewOnboardingTitleOrganizationName"
                name="organizationName"
                placeholder={
                  <FormattedMessage
                    id="a44688ab-d1a1-4d43-90ea-b42631009d38"
                    defaultMessage="Company Name"
                  />
                }
                displayRequiredAsterisk
                useStyledInput
              />

              <FormGroupErrors fields={["organizationName"]} alert />
            </FormGroup>
          </div>
          <Button
            className={`${CX}--save-button`}
            buttonColor="action"
            variant="primary"
            automationId="nononboarded-intro-modal-button"
            onClick={handleSubmit((data) => {
              updateTitleAgencyMutateFn({
                variables: {
                  input: {
                    organizationId,
                    organizationName: data.organizationName,
                    firstName: data.firstName,
                    lastName: data.lastName,
                  },
                },
              })
                .then(() => {
                  const search = encodeSearchParams(new URLSearchParams({ flow: NONONBOARDED }));
                  navigate(`${location.pathname}?${search}`, { replace: true });
                })
                .catch(() => {
                  captureException(
                    new Error(
                      `Organization (${organizationId}) failed to onboard in the nononboarded Title Agent flow.`,
                    ),
                  );
                })
                .finally(() => {
                  setIsOnboardingModalOpen(false);
                });
            })}
          >
            <FormattedMessage
              id="4c3c02e0-6aa4-4302-8fe2-615e00cf4949"
              defaultMessage="Save and Continue"
            />
          </Button>
        </div>
      </WorkflowModal>
    );
  }
  return null;
};

const InnerForm = reduxForm<FormValues, Props>({
  form: "TransactionViewOnboarding",
  validate,
})(TransactionViewTitleOnboardingModal);

function FormWrapper(props: WrapperProps) {
  const { data, loading } = useQuery(TransactionViewTitleOnboarding);
  return loading ? null : <InnerForm {...props} user={data!.viewer.user!} />;
}

export default FormWrapper;
