import "common/mortgage/transactions/edit/sub_forms/details/index.scss";

import { PureComponent, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { MortgageTransactionType } from "graphql_globals";
import { QueryWithLoading } from "util/graphql/query";
import { DeprecatedTextField } from "common/form/fields/text";
import { DeprecatedSelectField } from "common/form/fields/select";
import StyledTransactionType from "common/mortgage/transactions/form/styled_transaction_type";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import TipWell from "common/core/tip_well";
import { ADDRESS_FIELDS } from "common/form/sub_forms/address";
import Button from "common/core/button";
import Link from "common/core/link";
import { DeprecatedFormRow } from "common/form/elements/row";
import FormGroupErrors from "common/form/group_errors";
import PropertyAddressForm from "common/mortgage/transactions/edit/sub_forms/property_address";
import { deprecatedSubForm } from "common/form/enhancers/sub_form";
import validatePointsOfContact from "common/mortgage/transactions/form/points_of_contact/validate";
import RequirementsService from "common/mortgage/transactions/requirements_service";
import { getRequirements } from "common/mortgage/transactions/requirements_service/service";
import { composeValidators } from "util/form";
import { validatePresence, validateIf } from "validators/form";
import {
  ALL_MORTGAGE_TRANSACTION_TYPES,
  MORTGAGE_TRANSACTION_REQUIREMENTS,
} from "constants/transaction";
import { PAPER_RECORDING_BLOG_POST } from "constants/marketing";
import { isConditional, isRequired } from "util/requirements";

import { UnavailableUnderwriterModal } from "./unavailable_underwriter_modal";
import TransactionDetailsSectionQuery from "./transaction_details_section_query.graphql";
import Styles from "./index.module.scss";

const messages = defineMessages({
  loanNumber: {
    id: "4f60494e-7b39-456a-9068-fa2c75a23a79",
    defaultMessage: "Loan Number",
  },
  edit: {
    id: "13726b78-814e-4259-847d-cbb8bcb59e17",
    defaultMessage: "Edit",
  },
  fileNumber: {
    id: "f26989d1-e1c8-48e4-aa8c-874ee78225a9",
    defaultMessage: "File Number",
  },
  transactionName: {
    id: "3b922dc7-ef9d-464f-8d58-979723209d9d",
    defaultMessage: "Transaction Name",
  },
  transactionType: {
    id: "4e475840-f6eb-4642-8682-3eeb4e20d882",
    defaultMessage: "Transaction Type",
  },
  propertyAddress: {
    id: "724a92f5-2638-4b3b-bd09-b0fe5fa5513d",
    defaultMessage: "Property Address",
  },
  addressLine1: {
    id: "a39e48f8-58e4-497e-9b26-980e09e5f88b",
    defaultMessage: "Address Line 1",
  },
  addressLine2: {
    id: "d37e8487-e44f-41d8-afcd-7d924f8deee8",
    defaultMessage: "Address Line 2",
  },
  city: {
    id: "3fb07c5c-5d75-4fc5-8034-277e9c73f882",
    defaultMessage: "City",
  },
  country: {
    id: "1a350cbd-0456-4da9-9299-516ac3f85d85",
    defaultMessage: "Country",
  },
  state: {
    id: "cd3ac3b7-f381-436d-af6b-30b120b2afbd",
    defaultMessage: "State",
  },
  zip: {
    id: "7e8b1631-4989-4f38-9a00-dc4089ce5b42",
    defaultMessage: "ZIP/Postal",
  },
  titleUnderwriter: {
    id: "cc347eeb-6465-4496-af96-942ce0319508",
    defaultMessage: "Title Underwriter",
  },
  paperRecordingTipHeader: {
    id: "92a1a7e5-b98d-4aea-ada5-6ab4cac627f5",
    defaultMessage: "How do I close online in a paper recording county?",
  },
  paperRecordingTip: {
    id: "871e34af-2537-4df4-a043-4d6540b0378b",
    defaultMessage:
      "Your customers can complete a fully online closing in any county in {state} with the addition of a wet-signed Declaration of Authenticity.",
  },
  recordingLocation: {
    id: "4b2660d8-0bfe-4053-a369-db4fa8a26be8",
    defaultMessage: "Recording Location",
  },
});

/** @type {
 * (props: {
 *   transaction: { organization: { id: string }, transactionType: string },
 *   titlePlaceOrderEnabled: boolean,
 *   lenderPlaceOrderEnabled: boolean,
 *   usersOrgCreatedTransaction: boolean,
 * }) => boolean
 } */
const titleUnderwriterRequired = ({
  transaction,
  titlePlaceOrderEnabled,
  lenderPlaceOrderEnabled,
  usersOrgCreatedTransaction,
}) => {
  const { transactionType, underwriterOverride } = transaction;
  const req = getRequirements(transactionType, "title");

  return (
    !titlePlaceOrderEnabled &&
    !lenderPlaceOrderEnabled &&
    !underwriterOverride &&
    isRequired(req[MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER]) &&
    !usersOrgCreatedTransaction
  );
};

export const validationRules = (_values, props) =>
  composeValidators(
    validatePresence({ field: "transactionType", label: "Transaction type" }),
    validateIf({
      field: "fileNumber",
      condition: () => {
        const transactionType = props?.transaction?.transactionType;
        const req = getRequirements(transactionType, "title");
        return (
          transactionType === MortgageTransactionType.loan_mod_borrower ||
          isRequired(req[MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER])
        );
      },
      validation: validatePresence({ field: "fileNumber", label: "File Number" }),
    }),
    validateIf({
      field: "titleUnderwriter",
      condition: () => titleUnderwriterRequired(props),
      validation: validatePresence({ field: "titleUnderwriter", label: "Title Underwriter" }),
    }),
    validateIf({
      field: "recordingLocation",
      condition: () => {
        const transactionType = props?.transaction?.transactionType;
        const req = getRequirements(transactionType, "title");
        return isRequired(req[MORTGAGE_TRANSACTION_REQUIREMENTS.RECORDING_LOCATION]);
      },
      validation: validatePresence({ field: "recordingLocation", label: "Recording location" }),
    }),
    validatePointsOfContact,
  );

/**
 * Convert one title underwriter data item into a format usable by react select.
 * @param {Object} underwriter Title underwriter data fetched by the
 * TitleOrganizationTransactionFragment.
 */
const titleUnderwriterItem = (underwriter) => {
  return { value: underwriter.id, label: underwriter.name };
};

/**
 * Convert an array of title underwriter data into a format usable by the select input.
 * @param {Array<Object>} underwriters An array of title underwriter data fetched by the
 * TitleOrganizationTransactionFragment.
 *
 * @return {Array<Object>} An array of underwriter items, or [] if underwriters was falsy.
 */
const titleUnderwriterItems = (underwriters) => (underwriters || []).map(titleUnderwriterItem);

const uniqUnderwriters = (underwriters, targetUnderwriter) => {
  // targetUnderwriter can be nil if a transaction was created without one
  // (which is the case for most GR transactions currently)
  const duplicateUnderwriter = underwriters.find((underwriter) => {
    return underwriter.id === targetUnderwriter?.id;
  });

  // [].find() can return undefined so we need to make sure that duplicateUnderwriter is not undefined
  if (!duplicateUnderwriter) {
    return [...underwriters, targetUnderwriter];
  }
  return [...underwriters];
};

function TransactionDetailsSectionContent(props) {
  const {
    intl,
    transaction,
    titleUnderwriters: rawTitleUnderwriters,
    formName,
    recordingLocations,
    isClosingOps,
    readOnly,
    canSendToSigner,
  } = props;
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [editingTransactionType, setEditingTransactionType] = useState(false);

  const { transactionType, recordingLocation } = transaction;

  const titleUnderwriters = useMemo(() => {
    // The Nononboarded Title Project made it possible for lender-initiated transactions
    // to select a title agency w/o knowing beforehand its eligible titleunderwriters, instead
    // allowing the lender to choose from title underwriters by recording location state alone.
    // Since we can only toggle LD flags on the lender side(as nononboarded title agents get created outside of our
    // own control), title agencies can only see underwriters they've explicitly chosen to work with.
    // To bypass this restriction and allow lender chosen underwriters to show up on the
    // title agency side, we need to inject the chosen underwriter into the title agent's underwriter list.
    if (transaction.organization.lenderAccess && transaction.titleUnderwriter) {
      // Underwriters can be undefined if there is no recording location set from API
      return uniqUnderwriters(rawTitleUnderwriters || [], transaction.titleUnderwriter);
    }
    return rawTitleUnderwriters;
  }, [rawTitleUnderwriters, transaction]);

  const requirements = getRequirements(transactionType, "title");
  const fileNumberRequired =
    transaction.transactionType === MortgageTransactionType.loan_mod_borrower ||
    isRequired(requirements[MORTGAGE_TRANSACTION_REQUIREMENTS.FILE_NUMBER]);

  const disabledFields =
    transaction.transactionType === MortgageTransactionType.other || editingTransactionType
      ? []
      : [
          ADDRESS_FIELDS.LINE_1,
          ADDRESS_FIELDS.LINE_2,
          ADDRESS_FIELDS.CITY,
          ADDRESS_FIELDS.STATE,
          ADDRESS_FIELDS.POSTAL,
        ];

  const underwritingTransaction = isConditional(
    getRequirements(transactionType, "lender")[MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER],
  );
  const showTitleUnderwriters =
    underwritingTransaction &&
    ((titleUnderwriters && titleUnderwriters.length > 0) || transaction.underwriterUnavailableCta);

  const paperRecordingTipwell =
    recordingLocation && !recordingLocation.erecordingSupported ? (
      <TipWell heading={intl.formatMessage(messages.paperRecordingTipHeader)}>
        {intl.formatMessage(messages.paperRecordingTip, { state: recordingLocation.usState.name })}{" "}
        <Link href={PAPER_RECORDING_BLOG_POST}>
          <FormattedMessage
            id="fd15d12b-c15c-4bf6-9807-ee57788bd9eb"
            defaultMessage="Learn more."
          />
        </Link>
      </TipWell>
    ) : null;

  let titleUnderwriterTipwell = null;
  if (transaction.underwriterUnavailableCta) {
    titleUnderwriterTipwell = (
      <TipWell
        heading={
          <span style={{ whiteSpace: "nowrap" }}>
            <FormattedMessage
              id="8b32f03d-207e-447d-917e-d033ffa6c7e1"
              defaultMessage="Selecting an underwriter"
            />
          </span>
        }
      >
        <div>
          <FormattedMessage
            id="38a8526f-7dc8-413a-a9dc-ad7df16ead39"
            defaultMessage="Underwriters will appear on this list if Notarize has been able to confirm that the underwriter allows online closings in this state."
          />
        </div>
        <div className={Styles.tipwellText}>
          <FormattedMessage
            id="a52235f1-b41a-4978-b2fd-757e9d9d658c"
            defaultMessage="If your underwriter is not listed, click the link below the field. We'll alert the lender, so they know to convert this closing to a hybrid or wet sign."
          />
        </div>
      </TipWell>
    );
  }

  return (
    <div>
      <SubForm className="TransactionDetailsSection">
        <SubFormSection>
          <DeprecatedFormRow>
            <div className="TransactionDetailsSection--EditTransactionType">
              <StyledTransactionType
                id="transactionType"
                name="transactionType"
                data-automation-id="transaction-type"
                transactionTypes={ALL_MORTGAGE_TRANSACTION_TYPES}
                placeholder={intl.formatMessage(messages.transactionType)}
                placeholderAsLabel
                disabled={!editingTransactionType}
              />
              {Boolean(isClosingOps && !editingTransactionType) && (
                <Button
                  buttonColor="action"
                  variant="secondary"
                  automationId="navigate-to-edit-transaction-type"
                  className="TransactionDetailsSection--EditTransactionType--Button"
                  onClick={() => {
                    setEditingTransactionType(true);
                  }}
                >
                  {intl.formatMessage(messages.edit)}
                </Button>
              )}
            </div>
            <FormGroupErrors
              fields={["transactionType"]}
              groupClassName="TransactionDetailsSection--FormGroup"
              errorClassName="TransactionDetailsSection--ValidationMessage"
            />
          </DeprecatedFormRow>
        </SubFormSection>
        <SubFormSection tipWell={titleUnderwriterTipwell} className="NoMargin">
          {
            /*
             * If we could not load any underwriters, hide this field. This typically shouldn't happen
             * but was found to occur in rare cases.
             */
            showTitleUnderwriters && (
              <DeprecatedFormRow>
                <DeprecatedSelectField
                  id="titleUnderwriter"
                  name="titleUnderwriter"
                  data-automation-id="title-underwriter-field"
                  placeholder={intl.formatMessage(messages.titleUnderwriter)}
                  items={titleUnderwriterItems(titleUnderwriters)}
                  placeholderAsLabel
                  displayRequiredAsterisk={titleUnderwriterRequired(props) && canSendToSigner}
                  useStyledInput
                />
                <FormGroupErrors
                  fields={["titleUnderwriter"]}
                  groupClassName="TransactionDetailsSection--FormGroup"
                  errorClassName="TransactionDetailsSection--ValidationMessage"
                />
                {transaction.underwriterUnavailableCta && (
                  <div className={Styles.subtext}>
                    <FormattedMessage
                      id="736bf3b3-5104-4c47-9377-0049206c2c62"
                      defaultMessage="Don't see your underwriter listed here?"
                      tagName="span"
                    />{" "}
                    <Link
                      onClick={() => {
                        setShowAlertModal(true);
                      }}
                      automationId="alert-the-lender-link"
                    >
                      <FormattedMessage
                        id="dd0fbc25-e7e7-4143-b20b-3c12ea891d26"
                        defaultMessage="Alert the Lender"
                        tagName="span"
                      />
                    </Link>
                  </div>
                )}
              </DeprecatedFormRow>
            )
          }
          <DeprecatedFormRow>
            <DeprecatedTextField
              id="fileNumber"
              name="fileNumber"
              data-automation-id="file-number"
              placeholder={intl.formatMessage(messages.fileNumber)}
              placeholderAsLabel
              useStyledInput
              displayRequiredAsterisk={fileNumberRequired}
            />
            <FormGroupErrors
              fields={["fileNumber"]}
              groupClassName="TransactionDetailsSection--FormGroup"
              errorClassName="TransactionDetailsSection--ValidationMessage"
            />
          </DeprecatedFormRow>
          <DeprecatedFormRow>
            <DeprecatedTextField
              id="loanNumber"
              name="loanNumber"
              data-automation-id="loan-number"
              placeholder={intl.formatMessage(messages.loanNumber)}
              placeholderAsLabel
              useStyledInput
              disabled={readOnly}
            />
          </DeprecatedFormRow>
        </SubFormSection>
      </SubForm>
      <SubForm className="PropertyAddressDetailsSection">
        <SubFormSection tipWell={paperRecordingTipwell}>
          <PropertyAddressForm
            disabledFields={disabledFields}
            disableRecordingLocation={transaction.transactionType === MortgageTransactionType.other}
            initialRecordingLocation={recordingLocation}
            initialRecordingLocations={recordingLocations}
            formName={formName}
          />
        </SubFormSection>
      </SubForm>
      {showAlertModal && (
        <UnavailableUnderwriterModal
          onClose={() => {
            setShowAlertModal(false);
          }}
          lenderName={transaction.organization.name}
          transactionId={transaction.id}
        />
      )}
    </div>
  );
}

TransactionDetailsSectionContent.propTypes = {
  intl: PropTypes.object.isRequired,
  titleUnderwriters: PropTypes.array,
  isClosingOps: PropTypes.bool,
  readOnly: PropTypes.bool.isRequired,
};

class TransactionDetailsSection extends PureComponent {
  render() {
    const {
      transaction: { transactionType, id, underwriterUnavailableCta },
    } = this.props;
    return (
      <RequirementsService transactionType={transactionType} organizationType="title">
        {({ requirements }) => {
          // If we do not need to load title underwriters, don't do it!
          const transactionSupportsUnderwriters = isConditional(
            requirements[MORTGAGE_TRANSACTION_REQUIREMENTS.TITLE_UNDERWRITER],
          );
          const {
            transaction: { titleUnderwriter, propertyAddress },
            canSendToSigner,
          } = this.props;

          // This object has to be created like this since even though the GraphQL TransactionDetailsSectionQuery
          // has a directive to only query for the recording locations if the searchRecordingLocations boolean is
          // true. The PropertyAddress variable has to be marked as required since the backend marks it as such
          // even though it is in a directive that could not return the recording locations.
          const formattedPropertyAddress = {
            city: propertyAddress?.city,
            country: propertyAddress?.country,
            line1: propertyAddress?.line1,
            line2: propertyAddress?.line2,
            postal: propertyAddress?.postal,
            state: propertyAddress?.state,
          };

          // Some notes:
          // 1. We don't need to show underwriter for transaction types that don't require underwriters
          // 2. Currently, it seems like we do not query for underwriters if there is no recording location. That
          //    could be an old requirement that doesn't necessarily still need to be respected, but need to confirm with product
          // 3. Docutech doesn't send recording locations but do want their nononboarded title agencies to add underwriters.
          //    Because of this, we must show Docutech title agencies all eligible title underwriters.
          // 4. Certain title agencies like First American want us to respect the list of underwriters that they support
          //    so we cannot just give them the entire underwriter list
          return formattedPropertyAddress ||
            transactionSupportsUnderwriters ||
            underwriterUnavailableCta ? (
            <QueryWithLoading
              query={TransactionDetailsSectionQuery}
              variables={{
                transactionId: id,
                propertyAddress: formattedPropertyAddress,
                searchRecordingLocations: Boolean(formattedPropertyAddress),
              }}
            >
              {({ data }) => {
                const underwriters = data?.node?.publicEligibleTitleUnderwriters || [];
                const recordingLocations = data?.viewer?.recordingLocationEligibilities || [];
                // This flow is for docutech title agents
                if (underwriterUnavailableCta && underwriters.length === 0) {
                  return (
                    <QueryWithLoading
                      query={TransactionDetailsSectionQuery}
                      variables={{
                        transactionId: id,
                        withTitleAgencies: false,
                        propertyAddress: formattedPropertyAddress,
                      }}
                    >
                      {({ data: additionalData }) => {
                        const underwritersWithoutTitleEligibility =
                          additionalData?.node?.publicEligibleTitleUnderwriters || [];
                        return (
                          <TransactionDetailsSectionContent
                            {...this.props}
                            titleUnderwriters={underwritersWithoutTitleEligibility}
                            recordingLocations={recordingLocations}
                            selectedUnderwriter={titleUnderwriter}
                            canSendToSigner={canSendToSigner}
                          />
                        );
                      }}
                    </QueryWithLoading>
                  );
                }
                return (
                  <TransactionDetailsSectionContent
                    {...this.props}
                    titleUnderwriters={underwriters}
                    recordingLocations={recordingLocations}
                    selectedUnderwriter={titleUnderwriter}
                    canSendToSigner={canSendToSigner}
                  />
                );
              }}
            </QueryWithLoading>
          ) : (
            <TransactionDetailsSectionContent {...this.props} canSendToSigner={canSendToSigner} />
          );
        }}
      </RequirementsService>
    );
  }
}

export default deprecatedSubForm({
  getValuesFor: ["transactionType"],
})((props) => <TransactionDetailsSection {...props} intl={useIntl()} navigate={useNavigate()} />);
